import { getColorForLabel } from "./utils";
import * as THREE from "three";
import { NodeObject } from "react-force-graph-3d";
import { Node } from "./index";

export const renderNode3D = (
  node: NodeObject<Node>,
  loadingNode: string[] | null,
  hoveredEntity: string | null,
): THREE.Object3D => {
  const group = new THREE.Group();

  // Create the main sphere (ball)
  const geometry = new THREE.SphereGeometry(5, 32, 32);
  const color = getColorForLabel(
    node.labels?.[node.labels.length - 1] || node.labels?.[0] || "",
  );
  let material = new THREE.MeshLambertMaterial({ color: color });

  if (hoveredEntity && node.labels && !node.labels.includes(hoveredEntity)) {
    material = new THREE.MeshLambertMaterial({
      color: color,
      opacity: 0.4,
      transparent: true,
    });
  }

  if (loadingNode && loadingNode.includes(node.id)) {
    material = new THREE.MeshLambertMaterial({
      color: color,
      opacity: 0.4,
      transparent: true,
    });
  }

  const sphere = new THREE.Mesh(geometry, material);
  group.add(sphere);

  // Add a text label
  // const sprite = new THREE.Sprite(
  //     new THREE.SpriteMaterial({
  //         map: createTextTexture(node.id),
  //         color: 0xffffff
  //     })
  // );
  // sprite.scale.set(24, 12, 1);
  // sprite.position.set(0, 7, 0);
  // group.add(sprite);

  return group;
};

function createTextTexture(text: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) throw new Error("Could not get 2D context");

  canvas.width = 256;
  canvas.height = 128;

  context.font = "Bold 24px Arial";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.fillText(text, 128, 64);

  return new THREE.CanvasTexture(canvas);
}
export const renderNode = (
  node: any,
  ctx: CanvasRenderingContext2D,
  globalScale: number,
  loadingNode: string[] | null,
  hoveredEntity: string | null,
) => {
  const label = node.node;
  // Setting a base small font size - will adjust dynamically later
  const baseFontSize = 6 / globalScale;
  ctx.font = `${baseFontSize}px Sans-Serif`;

  let splitLabel = label.split(/[\s_]+/);

  const nodeRadius = 5;
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  ctx.lineWidth = 2 / globalScale;

  ctx.fillStyle = getColorForLabel(node.type, 1);

  ctx.fillStyle = getColorForLabel(node.type);

  // Animate background opacity if node is loading
  if (loadingNode && loadingNode.includes(node.id)) {
    const now = Date.now();
    const opacity = 0.3 + Math.abs(Math.sin(now / 200)) * 0.5; // Oscillate between 0.3 and 0.8
    ctx.fillStyle = getColorForLabel(node.type, opacity);
    ctx.strokeStyle = `rgba(0, 0, 0, ${opacity})`;
  } else {
    ctx.strokeStyle = `rgba(0, 0, 0, 1)`;
  }

  if (hoveredEntity && node.type !== hoveredEntity) {
    ctx.fillStyle = getColorForLabel(node.type, 0.4);
    ctx.strokeStyle = `rgba(0, 0, 0, 0)`;
  }

  if (hoveredEntity && node.type === hoveredEntity) {
    const now = Date.now();
    const opacity = 0.3 + Math.abs(Math.sin(now / 200)) * 0.5; // Oscillate between 0.3 and 0.8
    ctx.strokeStyle = `rgba(0, 0, 0, ${opacity})`;
  }

  ctx.beginPath();
  ctx.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI, false);
  ctx.fill();
  ctx.stroke();

  // Calculate text dimensions with base font size
  const lineHeight = baseFontSize * 1.2;
  let maxWidth = 0;
  splitLabel = splitLabel.filter(Boolean); // Remove empty strings
  splitLabel.forEach((line: string) => {
    const width = ctx.measureText(line).width;
    maxWidth = Math.max(maxWidth, width);
  });
  let totalHeight = lineHeight * splitLabel.length;

  // Reduce threshold from 2.5 to 1.8 to delay transition to full text
  if (maxWidth <= nodeRadius * 1.8 && totalHeight <= nodeRadius * 1.8) {
    // For large nodes with full text, use larger font
    const largeFontSize = 9 / globalScale;
    ctx.font = `${largeFontSize}px Sans-Serif`;
    ctx.fillStyle = "#000";
    
    // Recalculate dimensions with the new font size
    const largeLineHeight = largeFontSize * 1.2;
    totalHeight = largeLineHeight * splitLabel.length;
    
    splitLabel.forEach((part: string, i: number) => {
      ctx.fillText(
        part,
        node.x,
        node.y - totalHeight / 2 + largeLineHeight * i + largeLineHeight / 2,
      );
    });
  } else {
    // Reset to base font for checking medium-sized text
    ctx.font = `${baseFontSize}px Sans-Serif`;
    maxWidth = 0;
    const splitLabelMid = [splitLabel[0], "..."];
    splitLabelMid.forEach((line: string) => {
      const width = ctx.measureText(line).width;
      maxWidth = Math.max(maxWidth, width);
    });
    totalHeight = lineHeight * splitLabel.length;
    
    // Keep medium text threshold the same
    if (maxWidth <= nodeRadius * 1.5 && totalHeight <= nodeRadius * 1.5) {
      // For medium nodes, use medium font size
      const mediumFontSize = 7 / globalScale;
      ctx.font = `${mediumFontSize}px Sans-Serif`;
      ctx.fillStyle = "#000";
      
      // Recalculate with new font size
      const mediumLineHeight = mediumFontSize * 1.2;
      totalHeight = mediumLineHeight * splitLabelMid.length;
      
      splitLabelMid.forEach((part: string, i: number) => {
        ctx.fillText(
          part,
          node.x,
          node.y - totalHeight / 2 + mediumLineHeight * i + mediumLineHeight / 2,
        );
      });
    } else {
      // Keep using small font for tiny nodes
      const short = splitLabel[0].substring(0, 3) + "...";
      maxWidth = ctx.measureText(short).width;
      totalHeight = lineHeight * 1;

      if (maxWidth <= nodeRadius * 2 && totalHeight <= nodeRadius * 2) {
        ctx.fillStyle = "#000";
        splitLabel = [short];
        splitLabel.forEach((part: string, i: number) => {
          ctx.fillText(
            part,
            node.x,
            node.y - totalHeight / 2 + lineHeight * i + lineHeight / 2,
          );
        });
      }
    }
  }
};
